import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";

const state = {
}
const mutations = {
}
const actions = {
  async createMonthlySchedule({state, rootState}, payload) {
    const res = await ApiCaller(null, 'admin').post(URLS.MONTHLY_SCHEDULES(),{
      monthly_schedule: Object.assign({}, rootState.store.editMonthlySchedule, {daily_routines_attributes: Object.assign([], rootState.store.editMonthlySchedule.daily_routines.map(e => Object.assign({}, e, {weekday: e.weekday.value})))}, {special_day_routine_attributes: Object.assign({}, rootState.store.editMonthlySchedule.special_day_routine )})
    })
    if (res.status == 200){
      Message.success(res.data.message)
    }
  },
  async updateMonthlySchedule({state, rootState}, payload) {
    const res = await ApiCaller(null, 'admin').put(URLS.UPDATE_MONTHLY_SCHEDULE(payload.id),{
      monthly_schedule: Object.assign({}, rootState.store.editMonthlySchedule, {daily_routines_attributes: Object.assign([], rootState.store.editMonthlySchedule.daily_routines)}, {special_day_routine_attributes: Object.assign({}, rootState.store.editMonthlySchedule.special_day_routine )})
    })
    if (res.status == 200){
      Message.success(res.data.message)
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
