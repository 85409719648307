import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";

const state = {
  userGroupBookings: [],
  editBooking: {
    showModal: false,
    id: ''
  },
  countAwaitBooking : 0,
  reBooking: {
    showModal: false,
    id: ''
  },
  cancelBooking: {
    showModal: false,
    id: '',
    check_cancel_booking: false
  },
  userBookings: [{count_in_queue: '', store:{name: ''}}],
  filterBooking: {
    past_bookings: false,
    category_id: 0
  },
  eventBookings: []
}
const mutations = {
  COUNT_AWAIT_BOOKING(state, payload){
    state.countAwaitBooking = payload
  },
  SET_USER_BOOKINGS(state, payload){
    state.userBookings = payload
  },
  SET_GROUP_BOOKINGS(state, payload){
    state.userGroupBookings = payload
  },
  SET_BOOKING_EVENT_USERS(state, payload){
    state.eventBookings = payload
  },
}
const actions = {
  async fetchBookings({ commit }){
    const res = await ApiCaller(null, 'user').get(URLS.USER_BOOKINGS())
    if (res.data){
      commit('SET_USER_BOOKINGS', res.data.bookings)
      commit('SET_GROUP_BOOKINGS', res.data.group_bookings)
    }
  },
  async userCancelBooking({commit, state}, payload) {
    const res = await ApiCaller(null, 'user').post(URLS.USER_CANCEL_BOOKING(payload))
    return res.data
  },
  async fetchUserListBookings({ commit, state }, payload = ""){
    const res = await ApiCaller(null, 'user').get(URLS.USER_LIST_BOOKINGS(), {
      params: Object.assign({}, state.filterBooking, { service_id: payload.service_id, region: payload.region })
    })
    if (res.data){
      commit('SET_USER_BOOKINGS', res.data.bookings)
    }
  },
  async countAwaitBookings({ commit, state }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.COUNT_AWAIT_BOOKING(),{
      params: Object.assign({}, {service_id: payload})
    })
    commit('COUNT_AWAIT_BOOKING', res.data.count_await_bookings)
  },
  async fetchEventBookings({ commit, rootState }, payload=""){
    const res = await ApiCaller(null, 'user').get(URLS.USER_EVENT_BOOKINGS())
    if (res.data){
      commit('SET_BOOKING_EVENT_USERS', res.data.event_bookings)
    } else {
      Message.error('イベント予約の取得に失敗しました')
    }
  },
}
export default {
  namespaced: true,
  state, mutations, actions
}