import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import AvatarUrl from '../../../src/assets/images/user-chat.png'
import {Message} from "element-ui";

const state = {
  visibleFloor: false,
  editFloor: {image: AvatarUrl},
  isEditFloor: false,
  floors: []
}
const mutations = {
  SET_FLOORS(state, payload){
    state.floors = payload
  },
  SET_VISIBLE_FLOOR(state, payload){
    state.visibleFloor = payload
  },
  SET_EDIT_FLOOR(state, payload){
    state.editFloor = payload
  },
  SET_IS_EDIT_FLOOR(state, payload){
    state.isEditFloor = payload
  },
  SET_AVATAR_FLOOR_URL(state, payload){
    if (payload == null) {
      state.editFloor.image = AvatarUrl
    } else {
      state.editFloor.image = payload
    }
  },
}
const actions = {
  async fetchFloors({ commit }){
    const res = await ApiCaller(null, 'admin').get(URLS.FLOORS())
    if (res.data){
      commit('SET_FLOORS', res.data.floors)
    }
  },
  async createFloor({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').post(URLS.FLOORS(),{
      floor: payload
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchFloors')
    }
  },
  async updateFloor({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').put(URLS.FLOOR(payload.id),{
      floor: payload
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchFloors')
    }
  },
  async removeFloor({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').delete(URLS.FLOOR(payload.id))
    if (res.data.status){
      Message.success(res.data.message)
      commit('SET_VISIBLE_FLOOR', false)
      dispatch('fetchFloors')
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}