import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import Router from '../../user-router'

const state = {
  userEvents: [],
  selectEvent: {},
  selectEventDay: {}
}
const mutations = {
  SET_USER_EVENTS(state, payload){
    state.userEvents = payload
  },
  SET_SELECT_EVENTS(state, payload){
    state.selectEvent = payload
  },
  SET_SELECT_EVENT_DAY(state, payload){
    state.selectEventDay = payload
  },
}
const actions = {
  async fetchEvents({ commit, rootState }, payload=""){
    const res = await ApiCaller(null, 'user').get(URLS.USER_EVENTS())
    if (res.data){
      commit('SET_USER_EVENTS', res.data.events)
    } else {
      Message.error('イベント取得に失敗しました')
    }
  },
  async createEventBooking( { commit }, payload="") {
    const res = await ApiCaller(null, 'user').post(URLS.USER_CREATE_EVENT_BOOKING(payload.event_id), {
      event_booking: {
        event_day_id: payload.id,
        status: 1
      }
    })
    if (res.status){
    } else {
      Message.error('イベントの予約に失敗しました')
    }
    return res.status
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
