import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";

const state = {
  editCustomer: {},
}
const mutations = {
  SET_EDIT_CUSTOMER(state, payload){
    state.editCustomer = payload
  }
}
const actions = {
  async fetchCustomer({commit}){
    const res = await ApiCaller(null, 'user').get(URLS.GET_CUSTOMER())
    if (res.data.customer){
      commit('SET_EDIT_CUSTOMER', res.data.customer)
    }
    return res
  },
  async updateCustomer({commit, dispatch}, payload){
    const res = await ApiCaller(null, 'user').post(URLS.CUSTOMERS(),{
      customer: payload
    })
    if(res.data.status){
      dispatch('fetchCustomer')
    }
    return res
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
