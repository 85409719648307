const getters = {
  // app
  adminLeftMenu: state => state.leftMenu.adminMenu,
  accountLeftMenu: state => state.leftMenu.accountMenu,
  hwid: state => state.app.hwid,
  urlRichMenu: state => state.app.urlRichMenu,
  breadCrumbs: state => state.app.breadCrumbs,
  condition: state => state.app.condition,
  externalLink: state => state.app.externalLink,
  isVisibleCamera: state => state.app.isVisibleCamera,
  isVisibleParcoConfirm: state => state.app.isVisibleParcoConfirm,
  isCollapsed: state => state.app.isCollapsed,
  isLoggedIn: state => state.app.isLoggedIn,
  isActive: state => state.app.isActive,
  isLineToken: state => state.app.isLineToken,
  isConnectedParco: state => state.app.isConnectedParco,
  accountType: state => state.app.accountType,
  isConversionPhase: state => state.app.isConversionPhase,
  messageObject: state => state.app.messageObject,
  modalP1Text: state => state.app.modalP1Text,
  modalP2Text: state => state.app.modalP2Text,
  pagination: state => state.page.pagination,
  pageTitle: state => state.page.pageTitle,
  visibleExtenalLink: state => state.app.visibleExtenalLink,
  visibleModal: state => state.app.visibleModal,
  wid: state => state.app.wid,
  //account
  activeComponentCreate: state => state.account.activeComponentCreate,
  editAccount: state => state.account.editAccount,
  accounts: state => state.account.accounts,
  isCreateAccount: state => state.account.isCreateAccount,
  accountIsSuperAdmin: state => state.account.accountIsSuperAdmin,
  //answerSheet
  editAnswerSheet: state => state.answerSheet.editAnswerSheet,
  isEditAnswerSheet: state => state.answerSheet.isEditAnswerSheet,
  //booking
  adminFilterBooking: state => state.booking.adminFilterBooking,
  visibelImage: state => state.booking.visibelImage,
  currentBooking: state => state.booking.currentBooking,
  blankBooking: state => state.booking.blankBooking,
  bookingStatuses: state => state.booking.bookingStatuses,
  bookings: state => state.booking.bookings,
  activeNomalBookingComponent: state => state.booking.activeNomalBookingComponent,
  editNomalBooking: state => state.booking.editNomalBooking,
  // userBooking
  cancelBooking: state => state.userBooking.cancelBooking,
  reBooking: state => state.userBooking.reBooking,
  editBooking: state => state.userBooking.editBooking,
  filterBooking: state => state.userBooking.filterBooking,
  userBookings: state => state.userBooking.userBookings,
  eventBookings: state => state.userBooking.eventBookings,
  userGroupBookings: state => state.userBooking.userGroupBookings,
  countAwaitBooking: state => state.userBooking.countAwaitBooking,
  //userCustomer
  editCustomer: state => state.userCustomer.editCustomer,
  //schedule
  schedules: state => state.schedule.schedules,
  selectedEvents: state => state.schedule.selectedEvents,
  selectedMultipleEvents: state => state.schedule.selectedMultipleEvents,
  currentDay: state => state.schedule.currentDay,
  events: state => state.schedule.events,
  visibleFloor: state => state.floor.visibleFloor,
  editFloor: state => state.floor.editFloor,
  floors: state => state.floor.floors,
  isEditFloor: state => state.floor.isEditFloor,
  menuFixed: state => state.menuFixedCustomer.menuFixed,
  //store
  currentStore: state => state.store.currentStore,
  visibleStore: state => state.store.visibleStore,
  editStore: state => state.store.editStore,
  storesList: state => state.store.storesList,
  stores: state => state.store.stores,
  isEditStore: state => state.store.isEditStore,
  newStore: state => state.store.newStore,
  activeListStore: state => state.store.activeListStore,
  activeStoreComponent: state => state.store.activeStoreComponent,
  editMonthlySchedule: state => state.store.editMonthlySchedule,
  //option
  options: state => state.option.options,
  editOption: state => state.option.editOption,
  visibleOption: state => state.option.visibleOption,
  isEditOption: state => state.option.isEditOption,
  //service
  visibleService: state => state.service.visibleService,
  editService: state => state.service.editService,
  currentService: state => state.service.currentService,
  services: state => state.service.services,
  bookingTypes: state => state.service.bookingTypes,
  activatedServices: state => state.service.activatedServices,
  isEditService: state => state.service.isEditService,
  //storeService
  storeVisibleService: state => state.storeService.visibleService,
  storeBookingTypes: state => state.storeService.storeBookingTypes,
  storeEditService: state => state.storeService.editService,
  storeServices: state => state.storeService.services,
  storeIsEditService: state => state.storeService.isEditService,
  //category
  visibleCategory: state => state.category.visibleCategory,
  editCategory: state => state.category.editCategory,
  categories: state => state.category.categories,
  categoryTypes: state => state.category.categoryTypes,
  isEditCategory: state => state.category.isEditCategory,
  //user
  userCategories: state => state.userCategory.userCategories,
  userCategoryStores: state => state.userCategory.userCategoryStores,
  userEvents: state => state.userEvents.userEvents,
  selectEvent: state => state.userEvents.selectEvent,
  selectEventDay: state => state.userEvents.selectEventDay,
  userFloors: state => state.userFloor.userFloors,
  userFloorStores: state => state.userFloor.userFloorStores,
  editStoreUser: state => state.userStore.editStoreUser,
  activeTab: state => state.userService.activeTab,
  visibleMember: state => state.user.visibleMember,
  userCurrentRegion: state => state.user.userCurrentRegion,
  //therapists
  visibleStaff: state => state.staff.visibleStaff,
  editStaff: state => state.staff.editStaff,
  staffs: state => state.staff.staffs,
  isEditStaff: state => state.staff.isEditStaff,
  staff_roles: state => state.staff.staff_roles,
  therapists: state => state.therapist.therapists,
  //questionnaire
  activeCreateQuestionnaire: state => state.questionnaire.activeCreateQuestionnaire,
  isEditQuestionnaire: state => state.questionnaire.isEditQuestionnaire,
  isShowQuestionnaire: state => state.questionnaire.isShowQuestionnaire,
  newQuestionnaire: state => state.questionnaire.newQuestionnaire,
  editQuestionnaire: state => state.questionnaire.editQuestionnaire,
  questionnaires: state => state.questionnaire.questionnaires,
  questionTypes: state => state.questionnaire.questionTypes,
  isFormManagementStore: state => state.questionnaire.isFormManagementStore,
  //master data
  departments: state => state.department.departments,
  // common
  daysInMonth: state => state.common.daysInMonth,
  currentMonth: state => state.common.currentMonth,
  currentYear: state => state.common.currentYear,
  // customer
  showModalQrCode: state => state.customer.showModalQrCode,
  //informations
  informations: state => state.information.informations,
  xmlToJsonInfo: state => state.information.xmlToJsonInfo,
  //event
  editEvent: state => state.event.editEvent,
  //linkRegions
  linkRegions: state => state.linkRegion.linkRegions,
  currentRegionMember: state => state.linkRegion.currentRegionMember,
}
export default getters
