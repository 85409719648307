const state = {
  activeTab: 0,
  listMenuService: [
    {
      id: 0,
      name: 'すべて',
      componentName: "",
      active: false
    },
    {
      id: 1,
      name: '初診',
      componentName: "",
      active: true
    },
    {
      id: 2,
      name: '2回目以降',
      componentName: "",
      false: false
    }
  ],
}
const mutations = {
  SET_ACTIVE_TAB(state, payload){
    state.activeTab = payload
  }
}
const actions = {
}
export default {
  namespaced: true,
  state, mutations, actions
}