import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";

const state = {
  newQuestionnaire: {
    description: '',
    name: '',
    questions: []
  },
  editQuestionnaire: {},
  questionnaires: [],
  questionTypes: [],
}
const mutations = {
  SET_QUESTION_TYPES(state, payload){
    state.questionTypes = payload
  },
  SET_IS_FORM_MANAGERMENT_STORE(state, payload){
    state.isFormManagementStore = payload
  },
  SET_EDIT_QUESTIONNAIRE(state, payload){
    state.editQuestionnaire = payload
  },
  SET_QUESTIONNAIRES(state, payload){
    state.questionnaires = payload
  }
}
const actions = {
  async fetchQuestionnaires({ commit }){
    const res = await ApiCaller(null, 'admin').get(URLS.ADMIN_QUESTIONNAIRES())
    if (res.data){
      commit('SET_QUESTIONNAIRES', res.data.questionnaires)
    }
  },
  async fetchQuestionnaire({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').get(URLS.ADMIN_QUESTIONNAIRE(payload))
    if (res.status == 200) {
      commit('SET_EDIT_QUESTIONNAIRE', res.data.questionnaire)
    } else {
      Message.error('無効なパス。')
    }
  },
  async createQuestionnaire({ state, commit, dispatch }, payload){
    let questions_attributes = payload.questions.map(e => Object.assign(e, { answer_options_attributes: e.answer_options }))
    const res = await ApiCaller(null, 'admin').post(URLS.ADMIN_QUESTIONNAIRES(),{
      questionnaire: Object.assign(payload, { questions_attributes })
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchQuestionnaires')
      return true
    } else{
      Message.error(res.data.message)
      return false
    }
  },
  async fetchQuestionTypes({state, commit, dispatch}, payload){
    const res = await ApiCaller(null, 'admin').get(URLS.QUESTION_TYPES())
    if (res.data){
       commit('SET_QUESTION_TYPES', res.data)
    }
  },
  async updateQuestionnaire({ state, commit, dispatch }, payload){
    let questions_attributes = payload.questions.map(e => Object.assign(e, { answer_options_attributes: e.answer_options }))
    const res = await ApiCaller(null, 'admin').put(URLS.ADMIN_QUESTIONNAIRE(payload.id),{
      questionnaire: Object.assign(payload, { questions_attributes })
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchQuestionnaires')
      return true
    } else {
      return false
    }
  },
  async removeQuestionnaire({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').delete(URLS.ADMIN_QUESTIONNAIRE(payload.id))
    if (res.data.status){
      Message.success(res.data.message)
      commit('SET_VISIBLE_QUESTIONNAIRE', false)
      dispatch('fetchQuestionnaires')
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
