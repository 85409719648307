import logoImage from '../../../src/assets/images/logo-welpa.png'
const state = {
  menuFixed: [
    {
      label: '予約する',
      label_urawa: '予約する',
      name: 'schedule',
      icon: 'calendar-date',
      componentName: 'UserCategories'
    },
    {
      label: '予約確認',
      label_urawa: '予約確認',
      name: 'booking',
      icon: 'clock',
      componentName: 'UserBookings'
    },
    {
      label: '施設情報',
      label_urawa: '施設情報',
      name: 'information',
      imageUrl: logoImage,
      icon: 'house-door',
      componentName: 'UserInformations',
      // componentName: 'RedirectPage',
      // link: 'https://wellness.parco.jp/welpa/shinsaibashi/'
    },
    {
      label: '特集記事',
      label_urawa: '特集記事',
      name: 'article',
      icon: 'book',
      componentName: 'RedirectPage',
      // link: 'https://wellness.parco.jp/welpa/shinsaibashi/feature/?app=1'
    },
    {
      label: '会員証',
      label_urawa: '会員証',
      name: 'card',
      icon: 'credit-card',
      componentName: 'UserMember'
    }
  ]
}
const actions = {}
export default {
  namespaced: true,
  state
}
