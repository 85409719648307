import Vue from 'vue'
import VueRouter from 'vue-router'
import UserRouters from './router/user'
import FrontRouters from './router/user-front'

const routes = UserRouters.concat(FrontRouters)

Vue.component('user-layout', () => import('../src/layout/User'))
Vue.component('blank-layout', () => import('../src/layout/Blank'))

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)
  if (to.matched.some(record => record.meta.user && !record.meta.login)) {
    // if (localStorage.getItem(JWT_USER_KEY) == null) {
    //   await window.liff.init({ liffId: process.env.LIFF_ID })
    //   window.liff.logout()
    // }
  }
  next()
})

export default router
