// const Page404 = () => import('../pages/front/Page404')
const Privacy = () => import("../pages/front/Privacy")
const Maintain = () => import("../pages/front/Maintain")
const UserLogin = () => import("../pages/front/UserLogin")
const UserCategoryStores = () => import('../pages/users/information/categories/stores')
const CheckInCheckOut = () => import("../pages/front/CheckInCheckOut/CheckInCheckOut")
const QrStoreId = () => import("../pages/front/CheckInCheckOut/QrCode")
const SystemOut = () => import("../pages/front/SystemOut/SystemOut")

const routes = [
  {
    path: '/',
    component: UserCategoryStores,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'information',
      login: true
    }
  },
  // {
  //   path: '/user/login',
  //   name: 'UserLogin',
  //   component: UserLogin,
  //   meta: {
  //     layout: 'user',
  //     login: true
  //   },
  // },
  {
    path: '/user/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      showFixMenu: false,
      layout: 'blank',
      user: true
    }
  },
  {
    path: '/user/check_in_check_out',
    name: 'CheckInCheckOut',
    component: CheckInCheckOut,
    meta: {
      layout: 'blank',
    }
  },
  {
    path: '/user/system_out',
    name: 'SystemOut',
    component: SystemOut,
    meta: {
      layout: 'blank',
    }
  },
  {
    path: '/user/maintain',
    name: 'Maintain',
    component: Maintain,
    meta: {
      layout: 'blank',
    }
  },
  {
    path: '/user/qrcode/store',
    name: 'QrStoreId',
    component: QrStoreId,
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '*',
  //   name: 'Page404',
  //   component: Page404,
  //   meta: {
  //     layout: 'blank'
  //   }
  // },
]

export default routes
