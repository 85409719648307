import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import Router from "../../user-router";

const state = {
  editStoreUser: {staffs:[{image: {url: ''}}], services: []}
}
const mutations = {
  SET_EDIT_STORE_USER(state, payload){
    state.editStoreUser = payload
  }
}
const actions = {
  async fetchStoreUser({ commit }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.STORE_USER(payload))
    if (res.data.status){
      Router.push({name: 'UserInformations'})
    } else {
      commit('SET_EDIT_STORE_USER', res.data)
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
