import axios from 'axios'
import {JWT_ACCOUNT_KEY, JWT_ADMIN_KEY, JWT_USER_KEY} from "./constant";

//customHeader is user for upload image
export const ApiCaller = (customHeader = null, requestType = false) => {
  let token = ''
  if (requestType === 'admin') {
    token = localStorage.getItem(JWT_ADMIN_KEY)
  } else if (requestType === 'account') {
    token = localStorage.getItem(JWT_ACCOUNT_KEY)
  } else if(requestType === 'user'){
    token = localStorage.getItem(JWT_USER_KEY)
  }
  const header = {
    'Authorization': `Bearer ${ token }`,
  }
  const instance = axios.create({
    headers: Object.assign(customHeader || {}, header),
    timeout: 25000
  })
  instance.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem(JWT_ADMIN_KEY)
        localStorage.removeItem(JWT_USER_KEY)
      }
    }
    return Promise.reject(error)
  })
  return instance
}
