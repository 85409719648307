import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import ImageUrl from '../../../src/assets/images/user-chat.png'
import {Message} from "element-ui";

const state = {
  visibleCategory: false,
  editCategory: {image: ImageUrl},
  isEditCategory: false,
  categories: [],
  categoryTypes: []
}
const mutations = {
  SET_CATEGORIES(state, payload){
    state.categories = payload
  },
  SET_CATEGORY_TYPES(state, payload){
    state.categoryTypes = payload
  },
  SET_VISIBLE_CATEGORY(state, payload){
    state.visibleCategory = payload
  },
  SET_EDIT_CATEGORY(state, payload){
    state.editCategory = payload
  },
  SET_IS_EDIT_CATEGORY(state, payload){
    state.isEditCategory = payload
  },
  SET_IMAGE_CATEGORY_URL(state, payload){
    if (payload == null) {
      state.editCategory.image = AvatarUrl
    } else {
      state.editCategory.image = payload
    }
  },
}
const actions = {
  async fetchCategories({ commit }){
    const res = await ApiCaller(null, 'admin').get(URLS.CATEGORIES())
    if (res.data){
      commit('SET_CATEGORIES', res.data.categories)
    }
  },
  async fetchCategoryTypes({state, commit, rootState}){
    const response = await ApiCaller(null, 'admin').get(URLS.CATEGORY_TYPES())
    if (response.data){
      commit('SET_CATEGORY_TYPES', response.data)
    }
  },
}
export default {
  namespaced: true,
  state, mutations, actions
}