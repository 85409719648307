import {Message} from "element-ui";
import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";

const state = {
  answerSheets: [],
  editAnswerSheet: {questionnaire: {name: '', description: ''}},
  isEditAnswerSheet: false
}
const mutations = {
  SET_ANSWER_SHEETS(state, payload) {
    state.answerSheets = payload
  },
  SET_EDIT_ANSWER_SHEET(state, payload){
    state.editAnswerSheet = payload
  },
  SET_IS_EDIT_ANSWER_SHEET(state, payload) {
    state.isEditAnswerSheet = payload
  },
}
const actions = {
  async userCreateAnswerSheet({ commit, rootState }) {
    const res = await ApiCaller(null, 'user').post(URLS.USER_ANSWER_SHEETS(), {
      answer_sheet: Object.assign({}, state.editAnswerSheet,
          {
            answers_attributes: Object.assign([], state.editAnswerSheet.answers.map(e => {
              return Object.assign({}, e, {
                lock_question_attributes: Object.assign({}, e.lock_question,
                    { lock_answer_options_attributes: e.lock_question.lock_answer_options}) })}))})})

    if (res.status == 200) {
      return res.data
    } else {
      // Message.error('Server Error!')
    }
  },
  async userUpdateAnswerSheet({ commit, rootState }) {
    const res = await ApiCaller(null, 'user').put(URLS.USER_ANSWER_SHEET(state.editAnswerSheet.id), {
      answer_sheet: Object.assign({}, state.editAnswerSheet,
        {
          answers_attributes: Object.assign([], state.editAnswerSheet.answers.map(e => {
            return Object.assign({}, e, {
              lock_question_attributes: Object.assign({}, e.lock_question,
                { lock_answer_options_attributes: e.lock_question.lock_answer_options}) })}))})})

    if (res.status == 200) {
      return res.data
    } else {
      // Message.error('Server Error!')
    }
  },

  async fetchAnswerSheetByQuestionnaire({ state, commit, dispatch }, payload) {
    const res = await ApiCaller(null, 'admin').get(URLS.ADMIN_NEW_ANSWER_SHEETS(), {
      params: { questionnaire_id: payload }
    })
    if (res.status == 200) {
      commit('SET_EDIT_ANSWER_SHEET', res.data.answer_sheet)
    }
  },
  async fetchAnswerSheet({ state, commit, dispatch }, payload) {
    const res = await ApiCaller(null, 'user').get(URLS.USER_NEW_ANSWER_SHEETS(), {
      params: { booking_id: payload}
    })
    if (res.status == 200) {
      commit('SET_EDIT_ANSWER_SHEET', res.data.answer_sheet)
    }
  },

  async fetchBookingAnswerSheets({ state, commit, dispatch }, payload) {
    const res = await ApiCaller(null, 'user').get(URLS.USER_BOOKING_ANSWER_SHEETS(), {
      params: { booking_id: payload}
    })
    if (res.status == 200 && res.data.answer_sheets.length > 0) {
      commit('SET_EDIT_ANSWER_SHEET', res.data.answer_sheets[0])
    } else {
      dispatch('fetchAnswerSheet', payload)
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
