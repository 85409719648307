import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";
import Router from "../../user-router";

const state = {
  userFloors: [],
  userFloorStores: { floor: {image: {url: ''}}, floor_stores: [{image: {url: ''}}] }
}
const mutations = {
  SET_FLOORS_USER(state, payload){
    state.userFloors = payload
  },
  SET_FLOOR_STORES_USER(state, payload){
    state.userFloorStores = payload
  }
}
const actions = {
  async fetchFloorsUser({ commit }){
    const res = await ApiCaller(null, 'user').get(URLS.FLOORS_USER())
    if (res.data){
      commit('SET_FLOORS_USER', res.data.floors)
    }
  },
  async fetchFloorStoresUser({ commit }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.FLOOR_STORE_USER(payload))
    if (res.data.status){
      // Message.error({
      //   message: res.data.message
      // })
      Router.push({name: 'UserInformations'})
    } else {
      commit('SET_FLOOR_STORES_USER', res.data)
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}