import ImageUrl from "../../../src/assets/images/user-chat.png";
import Cookies from 'js-cookie'

const state = {
  currentStore: JSON.parse(Cookies.get('currentStore') || JSON.stringify({})),
  dailyRoutines: [],
  storesList: JSON.parse(localStorage.getItem('storesList') || JSON.stringify([{}])),
  visibleStore: false,
  newStore: {},
  activeListStore: true,
  stores: [],
  activeStoreComponent: {
    info: true,
    setting: true,
    questionnaires: true,
    editQuestionnaire: false,
    showQuestionnaire: false
  },
  editStore: {
    department_ids: [],
    store_basic_setting: {
      time_interval: '',
      time_service_default: '',
      deadline_booking_day: '',
      deadline_booking_hour: '',
      deadline_cancellation: '',
      line_notice_before_day: '',
      line_notice_before_hour: '',
      line_notice_people_waiting: ''
    }
  },
  editMonthlySchedule: {
    daily_routines: [],
    special_day_routine: {}
  }
}
const mutations = {
  SET_ACTIVE_STORE_COMPONENT(state, payload) {
    state.activeStoreComponent = payload
  },
  SET_CURRENT_STORE(state, payload) {
    state.currentStore = payload
  },
  SET_DAILY_ROUTINES(state, payload) {
    state.dailyRoutines = payload
  },
  SET_STORES_LIST(state, payload) {
    localStorage.setItem('storesList', JSON.stringify(payload))
    state.storesList = payload
  },
  INIT_STORES_LIST(state) {
    state.storesList = JSON.parse(localStorage.getItem('storesList') || JSON.stringify([{}]))
  },
  SET_STORES(state, payload){
    state.stores = payload
  },
  SET_VISIBLE_STORE(state, payload){
    state.visibleStore = payload
  },
  SET_NEW_STORE(state, payload){
    state.newStore = payload
  },
  SET_ACTIVE_LIST_STORE(state, payload){
    state.activeListStore = payload
  },
  SET_EDIT_STORE(state, payload){
    state.editStore = payload
  },
  SET_AVATAR_STORE_URL(state, payload){
    if (payload == null) {
      state.editStore.image = ImageUrl
    } else {
      state.editStore.image = payload
    }
  },
  SET_MONTHLY_SCHEDULE(state, payload){
    state.editMonthlySchedule = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state, mutations, actions
}
