const state = {
  daysInMonth: [],
  currentMonth: new Date().getMonth() + 1,
  currentYear: new Date().getFullYear()
}
const mutations = {
  SET_DAYS_IN_MONTH(state, payload){
    state.daysInMonth = payload
  },
  SET_CURRENT_MONTH(state, payload){
    state.currentMonth = payload
  },
  SET_CURRENT_YEAR(state, payload){
    state.currentYear = payload
  },
}
const actions = {
  getDaysInMonth({ commit }){
    var dt = new Date();
    var month = dt.getMonth() + 1;
    var year = dt.getFullYear();
    var numberDayInMonth = new Date(year, month, 0).getDate();
    var daysInMonth = Array.from({length: numberDayInMonth}, (_, i) => i + 1)
    commit('SET_DAYS_IN_MONTH', daysInMonth.map(e => Object.assign({}, {value: e, label: e + "日"})))
  },
}
export default {
  namespaced: true,
  state, mutations, actions
}
