const UserSignUp = () => import('../pages/users/ParcoInfo/SignUp.vue')
const ParcoBooking = () => import('../pages/users/ParcoInfo/ParcoBooking.vue')
const UserUpdateInfoCustomerAtBooking = () => import('../pages/users/UpdateInfoCustomerAtBooking')
const UserCategories = () => import('../pages/users/categories')
const UserBookings = () => import('../pages/users/Bookings')
const UserBookingSuccess = () => import('../pages/users/SingleBooking/UserBookingSuccess')
const UserStores = () => import('../pages/users/stores')
const UserStoreDetails = () => import('../pages/users/stores/Detail')
const UserCategoryStores = () => import('../pages/users/information/categories/stores')
const UserConfirmBooking = () => import('../pages/users/SingleBooking/ConfirmBooking')
const UserConfirmMultipleBooking = () => import('../pages/users/MultipleBooking/ConfirmMultipleBooking')
const UserInformations = () => import('../pages/users/information')
const UserInformationsFloors = () => import('../pages/users/information/floor/detail')
const UserInformationsNews = () => import('../pages/users/information/news/partial/DetailNewsEvent')
const UserStoreServices = () => import('../pages/users/services')
const UserServiceOptions = () => import('../pages/users/Options')
const UserServiceSingleBooking = () => import('../pages/users/SingleBooking/ServiceSingleBooking')
const UserServiceMultipleBooking = () => import('../pages/users/MultipleBooking/ServiceMultipleBooking')
const UserMultipleBookingSuccess = () => import('../pages/users/MultipleBooking/BookingSuccess')
const UserServiceAwaitBooking = () => import('../pages/users/AwaitBooking')
const UserAwaitBookingSuccess = () => import('../pages/users/AwaitBooking/partial/BookingSuccess')
const UserBookingsAnswerSheet = () => import('../pages/users/AnswerSheet')
const UserMember = () => import('../pages/users/member')
const UserOutSystem = () => import('../pages/users/OutSystem')
const UserProfile = () => import('../pages/users/profile/show')
const UserProfileEdit = () => import('../pages/users/profile/edit')

const routes = [
  {
    path: '/user/profile/edit',
    name: 'UserProfileEdit',
    component: UserProfileEdit,
    meta: {
      layout: 'user',
      user: true,
      showFixMenu: true,
      activeMenu: 'card'
    }
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      layout: 'user',
      user: true,
      showFixMenu: true,
      activeMenu: 'card'
    }
  },
  {
    path: '/user/member',
    name: 'UserMember',
    component: UserMember,
    meta: {
      layout: 'user',
      user: true,
      showFixMenu: true,
      activeMenu: 'card'
    }
  },
  {
    path: '/user/out_system',
    name: 'UserOutSystem',
    component: UserOutSystem,
    meta: {
      layout: 'user',
      user: true,
      showFixMenu: true,
    }
  },
  {
    path: '/user/categories',
    name: 'UserCategories',
    component: UserCategories,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      login: true,
      activeMenu: 'schedule'
    }
  },
  {
    path: '/user/bookings/:id/answer_sheet',
    name: 'UserBookingsAnswerSheet',
    component: UserBookingsAnswerSheet,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/confirm_booking',
    name: 'UserConfirmBooking',
    component: UserConfirmBooking,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/confirm_multiple_booking',
    name: 'UserConfirmMultipleBooking',
    component: UserConfirmMultipleBooking,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/categories/:id/store_group',
    name: 'UserStores',
    component: UserStores,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true,
      activeMenu: 'schedule'
    },
  },
  {
    path: '/user/informations',
    name: 'UserInformations',
    component: UserInformations,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'information'
    }
  },
  {
    path: '/user/informations/news_event/:id',
    name: 'UserInformationsNews',
    component: UserInformationsNews,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'information'
    }
  },
  {
    path: '/user/bookings',
    name: 'UserBookings',
    component: UserBookings,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'booking'
    }
  },
  {
    path: '/user/categories/:id/store',
    name: 'UserCategoryStores',
    component: UserCategoryStores,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'information'
    }
  },
  {
    path: '/user/informations/floor/:id',
    name: 'UserInformationsFloors',
    component: UserInformationsFloors,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'information'
    }
  },
  {
    path: '/user/store/:id',
    name: 'UserStoreDetails',
    component: UserStoreDetails,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true,
      activeMenu: 'schedule'
    }
  },
  {
    path: '/user/store/:id/services',
    name: 'UserStoreServices',
    component: UserStoreServices,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true,
    }
  },
  {
    path: '/user/services/:id/options',
    name: 'UserServiceOptions',
    component: UserServiceOptions,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/services/:id/single-booking',
    name: 'UserServiceSingleBooking',
    component: UserServiceSingleBooking,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/services/:id/multiple-booking',
    name: 'UserServiceMultipleBooking',
    component: UserServiceMultipleBooking,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/multiple-booking-success',
    name: 'UserMultipleBookingSuccess',
    component: UserMultipleBookingSuccess,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'schedule'
    }
  },
  {
    path: '/user/services/:id/await-booking',
    name: 'UserServiceAwaitBooking',
    component: UserServiceAwaitBooking,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/booking_success',
    name: 'UserBookingSuccess',
    component: UserBookingSuccess,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'schedule'
    }
  },
  {
    path: '/user/await_booking_success',
    name: 'UserAwaitBookingSuccess',
    component: UserAwaitBookingSuccess,
    meta: {
      showFixMenu: true,
      layout: 'user',
      user: true,
      activeMenu: 'schedule'
    }
  },
  {
    path: '/user/info',
    name: 'UserSignUp',
    component: UserSignUp,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/parco_booking/:id',
    name: 'ParcoBooking',
    component: ParcoBooking,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  },
  {
    path: '/user/update_info_customer_booking',
    name: 'UserUpdateInfoCustomerAtBooking',
    component: UserUpdateInfoCustomerAtBooking,
    meta: {
      showFixMenu: false,
      layout: 'user',
      user: true
    }
  }
]

export default routes
