import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";

const state = {
    linkRegions: [],
    currentRegionMember: {}
}

const mutations = {
    SET_LINK_REGIONS(state, payload){
        state.linkRegions = payload
    },
    SET_CURRENT_REGION_MEMBER(state, payload){
        state.currentRegionMember = payload
    },
}

const actions = {
    async fetchLinkRegions({commit} ){
        const res = await ApiCaller(null, 'user').get(URLS.USER_LINK_REGIONS())
        return res
    }
}

export default {
    namespaced: true,
    state, mutations, actions
}