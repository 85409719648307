import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";

const state = {
  informations : [],
  xmlToJsonInfo: []
}
const mutations = {
  SET_XMLTOJSON_INFO(state, payload){
    state.xmlToJsonInfo = payload
  },
  SET_INFORMATIONS(state, payload){
    state.informations = payload
  }
}
const actions = {
  async fetchApiInformations ({ state, commit }, payload = ""){
    const res = await ApiCaller(null, 'user').get(URLS.GET_API_INFORMATIONS(), {
      params: Object.assign({}, {region: payload.region})
    })
    commit('SET_INFORMATIONS', res.data.news_and_event)
    commit('SET_XMLTOJSON_INFO', res.data.xml_to_json.cmsdata.shoplist.shop)
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}