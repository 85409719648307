<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<script>

const default_layout = 'blank'
export default {
  data() {
    return {
      message: "App!",
      token_customer:'',
      token_store:''
    }
  },
  computed: {
    layout(){
      return(this.$route.meta.layout || default_layout) + '-layout'
    }
  },

  methods: {}
}
</script>
