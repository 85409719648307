import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";

const state = {
  visibleService: false,
  editService: {},
  isEditService: false,
  services: [],
  storeBookingTypes: []
}
const mutations = {
  SET_STORE_SERVICES(state, payload){
    state.services = payload
  },
  SET_BOOKING_TYPES(state, payload){
    state.storeBookingTypes = payload
  },
  SET_VISIBLE_STORE_SERVICE(state, payload){
    state.visibleService = payload
  },
  SET_EDIT_STORE_SERVICE(state, payload){
    state.editService = payload
  },
  SET_IS_EDIT_STORE_SERVICE(state, payload){
    state.isEditService = payload
  }
}
const actions = {
  async fetchServices({ commit, rootState }){
    const res = await ApiCaller(null, 'account').get(URLS.STORE_SERVICES(), {
      params: Object.assign({}, rootState.page)
    })
    if (res.data){
      commit('SET_STORE_SERVICES', res.data.services)
      commit('SET_BOOKING_TYPES', res.data.booking_types)
      commit('page/SET_TOTAL', res.data.total, { root: true })
    }
  },
  async createService({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'account').post(URLS.STORE_SERVICES(),{
      service: payload
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchServices')
    }
  },
  async updateService({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'account').put(URLS.STORE_SERVICE(payload.id),{
      service: payload
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchServices')
    }
  },
  async removeService({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'account').delete(URLS.STORE_SERVICE(payload.id))
    if (res.data.status){
      Message.success(res.data.message)
      commit('SET_VISIBLE_STORE_SERVICE', false)
      dispatch('fetchServices')
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}