export function handleBookingTitle(event) {
    try {
        const current_date = new Date(event.start_time)
        if (isNaN(current_date)) {
            return `-`
        } else {
            const str_arr = current_date.toTimeString().split(':')
            const start_time = `${str_arr[0]}:${str_arr[1]}`
            const dayName = current_date.toLocaleString('ja', {weekday: 'short'})
            return `${current_date.getMonth() + 1}/${current_date.getDate()} (${dayName}) ${start_time}~`
        }
    } catch (e) {}
}

export function handleBookingInfo(event) {
    try {
        const current_date = new Date(event.start_time)
        const str_arr = current_date.toTimeString().split(':')
        const start_time = `${str_arr[0]}:${str_arr[1]}`
        return `${current_date.getMonth() + 1}/${current_date.getDate()} ${start_time}~`
    } catch (e) {
        console.log(e)
    }
}

export function handleDateToDay(time) {
    const current_date = new Date(time)
    return `${current_date.getMonth() + 1}/${current_date.getDate()}`
}

export function handleDateToHour(time) {
    try {
        const current_date = new Date(time)
        const str_arr = current_date.toTimeString().split(':')
        return `${str_arr[0]}:${str_arr[1]}`
    } catch {
        return '...'
    }
}