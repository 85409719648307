const state = {
  pagination: {
    page: 1,
    total: 0,
  },
  pageTitle: 'Welpa 心斎橋店'
}
const mutations = {
  SET_PAGE_TITLE(state, payload){
    state.pageTitle = payload
  },
  SET_TOTAL(state, payload) {
    state.total = payload
  },
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_PER_PAGE(state, payload) {
    state.per_page = payload
  },
  RESET_PAGINATION(state) {
    state = { page: 1, per_page: 30, pageSizes: [30, 50, 100, 300, 500], total: 0 }
  }
}
const actions = {

}

export default {
  namespaced: true,
  state, mutations, actions
}
