function _buildDaysFromMonth(day = new Date()) {
  let lastDayOfMonth = new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate();
  let date_arr = []
  let date_name_arr = []
  let today = day.getDate()
  for (let i = today; i < lastDayOfMonth + 1; i++) {
    let current_date = new Date(day.getFullYear(), day.getMonth(), i)
    date_arr.push({type: 'header', label: i, date: current_date.toLocaleDateString('en-GB')})
    let dayName = current_date.toLocaleString('ja', {weekday: 'short'})
    let headerGrid = `<span class="header-grid">${dayName}</span>`
    date_name_arr.push({label: headerGrid, dayName, type: 'header', date: current_date.toLocaleDateString('en-GB')})
  }
  return [date_arr, date_name_arr]
}

function _getEvent(data, event, hour_str, selectedEvents, day_name) {
  try {
    let label = ''
    let data_event = {}
    let index = data.findIndex(e => e.date == event.date && e.hour_str == hour_str)
    if( index > -1) {
      label = buildLabelBooking(data[index], selectedEvents)
      data_event = Object.assign({}, data[index])
    } else {
      label = buildLabelBooking()
      data_event = Object.assign({}, event, {status: 'fail'})
    }
    return Object.assign({}, data_event, { label, day_name })
  } catch (e) {
    console.log(e)
  }
}

function _buildHourStr(timeStr) {
  let str = new Date(timeStr).toTimeString()
  let str_arr = str.split(':')
  let result = `${str_arr[0]}:${str_arr[1]}`
  return result
}
function _buildHourList(data, dateList, selectedEvents, list_date_name) {
  let hours_str = [...new Set(data.map(e => _buildHourStr(e.start_time)))]
  let sv_hours_str = [...new Set(data.map(e => e.hour_str))]
  let result = new Array(hours_str.length)
  for(let i = 0; i < hours_str.length; i++) {
    result[i] = new Array(dateList.length)
    result[i][0] = { label: `<span class="time-grid">${hours_str[i]}-</span>`, value: sv_hours_str[i] }
    for(let j = 1; j < dateList.length; j++) {
      result[i][j] = _getEvent(data, dateList[j], sv_hours_str[i], selectedEvents, list_date_name[j].dayName)
    }
  }
  return result
}

// data format: [{day: 1, hour: 10, status: 'available'}, {day: 1, hour: 11, status: 'available'}]
export function buildCalendarData(date = new Date(), data, selectedEvents = null) {
  let result = _buildDaysFromMonth(date)
  result[0].unshift({label: ""})
  result[1].unshift({label: ""})
  let new_arr = _buildHourList(data, result[0], selectedEvents, result[1])
  return result.concat(new_arr)
}

export async function firstDayOfMonth(day) {
  let firstDate = await new Date(day.getFullYear(), day.getMonth(), 1);
  return firstDate.toLocaleDateString('en-GB')
}

export async function endDayOfMonth(day) {
  let endDate = await new Date(day.getFullYear(), day.getMonth() + 1, 0)
  return endDate.toLocaleDateString('en-GB');
}

export function buildLabelBooking(event = null, selectedEvents = null) {
  if (event == null) {
    return `<div class='event-slot event-grid-fail'></div>`
  } else if (selectedEvents == null) {
    return `<div class='event-slot event-grid-${event.status}'></div>`
  } else {
    let indexEvent = selectedEvents.findIndex( e => e.start_time == event.start_time && e.start_time != '')
    if (indexEvent != -1) {
      return `<div class='event-slot event-grid-${event.status} active'><span class="index-active-label">${indexEvent + 1}</span></div>`
    } else {
      return `<div class='event-slot event-grid-${event.status}'></div>`
    }
  }
}

export function buildTenMinuteTable(timeIntervalStoreSetting = null) {
  let timeInterval = parseInt(timeIntervalStoreSetting)
  const times = Array.prototype.concat.apply(
    [],
    [...Array(24).keys()].map(v => {
      let timeArr = []
      if (v < 5 || 23 < v ) { return ''}
      for (let i = 0; i < 60 / timeInterval; i++){
        let time = String(v).padStart(2, '0') + ':' + String(i * timeInterval).padStart(2, '0')
        timeArr.push(time)
      }
      return timeArr
    })
  );
  return times.filter(function(v) { return !(v === "") });
}
