import ImageUrl from "../../../src/assets/images/user-chat.png";
import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";

const state = {
  visibleStaff: false,
  editStaff: {image: ImageUrl},
  isEditStaff: false,
  staffs: [],
  staff_roles: [{
    label: '',
    value: ''
  }]
}
const mutations = {
  SET_STAFFS(state, payload){
    state.staffs = payload
  },
  SET_VISIBLE_STAFF(state, payload){
    state.visibleStaff = payload
  },
  SET_EDIT_STAFF(state, payload){
    state.editStaff = payload
  },
  SET_IS_EDIT_STAFF(state, payload){
    state.isEditStaff = payload
  },
  SET_STAFF_ROLES(state, payload){
    state.staff_roles = payload
  },
  SET_AVATAR_STAFF_URL(state, payload){
    if (payload == null) {
      state.editStaff.image = ImageUrl
    } else {
      state.editStaff.image = payload
    }
  },
}

const actions = {
  //therapist on User
  async fetchUserStaffs({ commit, rootState }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.USER_STAFFS(), {
      params: {service_id: payload}
    })
    if (res.data){
      commit('SET_STAFFS', res.data.staffs)
    }
  },
  async fetchRoles({commit}, payload){
    const res = await ApiCaller(null, 'admin').get(URLS.STAFF_ROLES())
    if(res.data){
      commit('SET_STAFF_ROLES', res.data)
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
