import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import Router from '../../user-router'

const state = {
  userCategories: [],
  userCategoryStores: {
    category: {
      image: {url: ''},
      stores: [
        {image: {url:''}}
      ]
    }
  },
}
const mutations = {
  SET_USER_CATEGORIES(state, payload){
    state.userCategories = payload
  },
  SET_USER_CATEGORY_STORES(state, payload){
    state.userCategoryStores = payload
  },
}
const actions = {
  async fetchCategories({ commit }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.USER_CATEGORIES(), {
      params: payload
    })
    if (res.data){
      commit('SET_USER_CATEGORIES', res.data.categories)
    }
  },

  async fetchCategoryBooking({ commit }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.LIST_CATEGORY_BOOKING(), {
      params: payload
    })
    if (res.data){
      commit('SET_USER_CATEGORIES', res.data.categories)
    }
  },

  async fetchCategoryInformation({ commit }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.LIST_CATEGORY_INFORMATION(), {
      params: payload
    })
    if (res.data){
      commit('SET_USER_CATEGORIES', res.data.categories)
    }
  },

  async fetchCategoryStores({commit}, payload){
    const res = await ApiCaller(null, 'user').get(URLS.CATEGORY_STORE_USER(payload))
    if (res.data.status){
      // Message.error({
      //   message: res.data.message
      // })
      Router.push({name: 'UserInformations'})
    } else {
      commit('SET_USER_CATEGORY_STORES', res.data)
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
