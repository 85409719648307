import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";

const state = {
  events: [],
  editEvent: {},
  selectEventDay: {},
  isEditEvent: false,
  visibleEvent: false,
  bookingModalFlg: false,
  eventBookingUsers:[]
}
const mutations = {
  SET_EVENTS(state, payload){
    state.events = payload
  },
  SET_EVENT_DAY(state, payload){
    state.selectEventDay = payload
  },
  SET_BOOKING_EVENT_USERS(state, payload){
    state.eventBookingUsers = payload
  },
  SET_VISIBLE_EVENT(state, payload){
    state.visibleEvent = payload
  },
  SET_BOOKING_MODAL(state, payload){
    state.bookingModalFlg = payload
  },
  SET_EDIT_EVENT(state, payload){
    state.editEvent = payload
  },
  SET_IS_EDIT_EVENT(state, payload){
    state.isEditEvent = payload
  }
}
const actions = {
  async fetchEvents({ commit, rootState }, payload=""){
    const res = await ApiCaller(null, 'admin').get(URLS.ADMIN_EVENTS())
    if (res.data){
      commit('SET_EVENTS', res.data.events)
    } else {
      Message.error('イベント取得に失敗しました')
    }
  },

  async updateEvent({ commit, rootState }, payload=""){
    const res = await ApiCaller(null, 'admin').put(URLS.ADMIN_EVENT_UPDATE(payload.event_id),{
      event_day: payload
    })
    if (res.status){
      Message.success('イベント更新に成功しました')
    } else {
      Message.error('イベント更新に失敗しました')
    }
  },

  async fetchEventBookings({ commit, rootState }, payload=""){
    const res = await ApiCaller(null, 'admin').get(URLS.ADMIN_EVENT_BOOKINGS(payload.event_id), {
      params: {
        event_day: payload
      }
    })
    if (res.data){
      commit('SET_BOOKING_EVENT_USERS', res.data.event_bookings)
    } else {
      Message.error('イベント予約の取得に失敗しました')
    }
  },
}
export default {
  namespaced: true,
  state, mutations, actions
}
