import {ApiCaller} from "../../utils/api";
import { URLS } from '../../utils/url'
import { Message } from 'element-ui'
import {buildCalendarData, buildLabelBooking, endDayOfMonth, firstDayOfMonth} from "../../plugins/buildCalenderData";
import {handleBookingTitle, handleBookingInfo} from "../../plugins/handleDate";

var blankMultipleEvents = [
    {
        formatedInfo: '-',
        formatedTitle: '',
        start_time: ''
    },
    {
        formatedInfo: '-',
        formatedTitle: '',
        start_time: ''
    },
    {
        formatedInfo: '-',
        formatedTitle: '',
        start_time: ''
    }
]

const state = {
    schedules: [],
    selectedEvents: [],
    selectedMultipleEvents: blankMultipleEvents,
    events: [],
    currentDay: new Date(),
}
const mutations = {
    RESET_SELECTED_EVENTS(state){
        state.selectedEvents = []
        state.selectedMultipleEvents = blankMultipleEvents
        localStorage.removeItem('selectedEvents')
        localStorage.removeItem('selectedMultipleEvents')
    },
    SET_EVENTS(state, payload) {
        state.events = payload
        if (payload.length > 0) {
            state.schedules = buildCalendarData(state.currentDay, payload, state.selectedEvents)
        } else {
            state.schedules = []
        }
    },
    SET_CURRENT_DAY(state, payload) {
        state.currentDay = payload
    },
    // selectedEvents
    SET_ACTIVATED_EVENTS(state, payload) {
        if (Array.isArray(payload)) {
            state.selectedEvents = payload
            if (state.selectedEvents.length > 0) {
                state.selectedEvents.forEach(e => {
                    if (e.index_x && e.index_y) {
                        let scheduleObject = state.schedules[e.index_x][e.index_y]
                        let label = buildLabelBooking(scheduleObject, state.selectedEvents)
                        Object.assign(state.schedules[e.index_x][e.index_y], { label })
                    } else if (e.start_time) {
                        return state.schedules.forEach(rows => {
                            let scheduleObject = rows.find(el => el.start_time == e.start_time)
                            if (scheduleObject != undefined) {
                                scheduleObject.label = buildLabelBooking(scheduleObject, state.selectedEvents)
                                return
                            }
                        })
                    }
                })
            }
        }
    },
    TOGGLE_SCHEDULE_LABEL(state, payload) {
        let currentObject = state.schedules[payload.index_x][payload.index_y]
        let label = buildLabelBooking(currentObject, state.selectedMultipleEvents)
        currentObject = Object.assign(currentObject, { label })
    },
    ADD_ACTIVATED_EVENT(state, payload) {
        if (state.selectedEvents.length < 3) {
            state.selectedEvents.push(payload)
            state.selectedEvents.forEach((e) => {
                e.formatedTitle = handleBookingTitle(e)
                e.label = buildLabelBooking(e, state.selectedEvents)
            })
        }
    },
    // selectedMultipleEvents 
    SET_SELECTED_MULTIPLE_EVENTS(state, payload) {
        if (Array.isArray(payload)) {
            state.selectedMultipleEvents = payload
            if (state.selectedMultipleEvents.length > 0) {
                state.selectedMultipleEvents.forEach(e => {
                    if (e.index_x && e.index_y) {
                        let scheduleObject = state.schedules[e.index_x][e.index_y]
                        let label = buildLabelBooking(scheduleObject, state.selectedMultipleEvents)
                        Object.assign(state.schedules[e.index_x][e.index_y], { label })
                    }
                })
            }
        }
    },
    ADD_SELECTED_MULTIPLE_EVENT(state, payload) {
        const index = state.selectedMultipleEvents.findIndex(e => e.start_time == payload.start_time)
        if (payload.start_time != null) {
            if ( index > - 1) {
                state.selectedMultipleEvents[index] = Object.assign(state.selectedMultipleEvents[index], { formatedInfo: '-', formatedTitle: '', start_time: '' })
            } else {
                const blankIndex = state.selectedMultipleEvents.findIndex(e => e.formatedInfo == '-')
                if ( blankIndex > -1) {
                    state.selectedMultipleEvents[blankIndex] = Object.assign(state.selectedMultipleEvents[blankIndex], payload, {
                        formatedTitle: handleBookingTitle(payload),
                        formatedInfo: handleBookingInfo(payload),
                    })
                }
            }
        }
    },
    SPLICE_SELECTED_MULTIPLE_EVENT(state, index) {
        let currentObject = state.selectedMultipleEvents[index]
        Object.assign(currentObject, { formatedInfo: '-', formatedTitle: '', start_time: '' })
        let scheduleObject = state.schedules[currentObject.index_x][currentObject.index_y]
        let label = buildLabelBooking(currentObject, state.selectedMultipleEvents)
        Object.assign(scheduleObject, { label })
    },
    EXCHANGE_SELECTED_MULTIPLE_EVENT(state, index) {
        let tmp_arr = Object.assign([], state.selectedMultipleEvents)
        tmp_arr[index] = state.selectedMultipleEvents[index - 1]
        tmp_arr[index - 1] = state.selectedMultipleEvents[index]
        state.selectedMultipleEvents = tmp_arr
        // change label index
        let currentObject = state.schedules[tmp_arr[index].index_x][tmp_arr[index].index_y]
        let label = buildLabelBooking(currentObject, state.selectedMultipleEvents)
        Object.assign(currentObject, { label })
        // change label index -1
        let lastObject = state.schedules[tmp_arr[index - 1].index_x][tmp_arr[index - 1].index_y]
        label = buildLabelBooking(lastObject, state.selectedMultipleEvents)
        Object.assign(lastObject, { label })
    }
}
const actions = {
    async fetchEvents({commit, state, rootState}, payload) {
        let menuOption = JSON.parse(localStorage.getItem('selectedOptions'))
        let res = await ApiCaller().get(URLS.FRONT_EVENTS(), { params: {
                on_month: state.currentDay.getMonth() + 1,
                on_year: state.currentDay.getFullYear(),
                service_id: payload.service_id,
                menu_option_ids: menuOption != null ? menuOption.map(e => e.id) : null,
                therapist_id: payload.therapist_id,
                booking_id: payload.booking_id
            }})
        if (res.status == 200 || 201) {
            if (res.data.status == 'not_valid_room') {
                Message.error({
                    message: '設定にエラーがありLINEからの予約ができません。お手数ですがお電話で直接お問い合わせください。',
                    showClose: true,
                    duration: 0
                  })
            } else {
                commit('SET_EVENTS', res.data)
                res.data.length <= 0 ? Message.error('設定にエラーがありLINEからの予約ができません。お手数ですがお電話で直接お問い合わせください。') : ''
            }
        }
    },

    async fetchSchedules() {
        let res = await ApiCaller(null, 'user').get(URLS.USER_SCHEDULES())
        if (res.status) {
            console.log('fetchSchedules -', res.message)
        } else {
            console.log('fail on fetchSchedules')
        }
    },

    async createSchedule({commit, dispatch}, payload) {
        const res = await ApiCaller(null, true).post(URLS.SCHEDULES(), {
            schedule: Object.assign({}, payload)
        })
        if (res) {
            if (res.data.status != 'false') {
                Message.success({
                    message: res.data.message,
                    type: 'success'
                })
                commit('SET_DIALOG_VISIBLE_SCHEDULE', false)
                dispatch('fetchSchedules')
            } else {
                Message.error({
                    message: res.data.message
                })
            }
        }
    },
}
export default {
    namespaced: true,
    state, mutations, actions
}
