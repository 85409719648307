import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";
import router from "../../user-router";

const state = {
  visibleService: false,
  editService: {},
  isEditService: false,
  services: [],
  activatedServices: [],
  bookingTypes: [],
  currentService: {}
}
const mutations = {
  SET_SERVICES(state, payload){
    state.services = payload
  },
  SET_VISIBLE_SERVICE(state, payload){
    state.visibleService = payload
  },
  SET_BOOKING_TYPES(state, payload){
    state.bookingTypes = payload
  },
  SET_EDIT_SERVICE(state, payload){
    state.editService = payload
  },
  SET_CURRENT_SERVICE(state, payload){
    localStorage.setItem('currentService', JSON.stringify(payload))
    state.currentService = payload
  },
  SET_ACTIVATED_SERVICES(state, payload){
    state.activatedServices = Object.assign({}, payload)
  },
  SET_IS_EDIT_SERVICE(state, payload){
    state.isEditService = payload
  }
}
const actions = {
  async fetchUserServices({ commit, rootState }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.USER_SERVICES(), {
      params: {store_id: payload.store_id, therapist_id: payload.therapist_id}
    })
    if (res.data.services.length > 0){
      commit('SET_SERVICES', res.data.services)
      commit('SET_ACTIVATED_SERVICES', state.services)
    } else {
      router.push({name: "UserStoreDetails", params: payload.id})
    }
  },
  async createService({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').post(URLS.SERVICES(),{
      service: payload
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchServices')
    }
  },
  async updateService({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').put(URLS.SERVICE(payload.id),{
      service: payload
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchServices')
    }
  },

  async movePositionService({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').post(URLS.MOVE_POSITION_SERVICE(payload.service_id),{
      data: payload
    })
    if (res.data.status){
      Message.success(res.data.message)
      dispatch('fetchServices')
    }
  },
  async removeService({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'admin').delete(URLS.SERVICE(payload.id))
    if (res.data.status){
      Message.success(res.data.message)
      commit('SET_VISIBLE_SERVICE', false)
      dispatch('fetchServices')
    }
  },
  // Service for User
  async fetchService({commit}, payload){
    const res = await ApiCaller(null, 'user').get(URLS.USER_SERVICE(payload))
    if (res.data){
      commit('SET_CURRENT_SERVICE', res.data.service)
      return true
    } else {
      return false
    }
  },
}
export default {
  namespaced: true,
  state, mutations, actions
}
