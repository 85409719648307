import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";

const state = {
  therapists: [],
}
const mutations = {
  SET_THERAPISTS(state, payload){
    state.therapists = payload
  }
}

const actions = {
  async fetchUserTherapists({ commit, rootState }, payload){
    const res = await ApiCaller(null, 'user').get(URLS.USER_THERAPISTS(), {
      params: { service_id: payload }
    })
    if (res.data){
      commit('SET_THERAPISTS', res.data.therapists)
    }
  }
}

export default {
  namespaced: true,
  state, mutations, actions
}
