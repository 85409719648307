import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {Message} from "element-ui";

const state = {
    editOption: {},
    isEditOption: false,
    options: [],
    visibleOption: false,
    activatedOptions: [],
}
const mutations = {
    SET_OPTIONS(state, payload){
        state.options = payload
    },
    SET_VISIBLE_OPTION(state, payload){
        state.visibleOption = payload
    },
    SET_EDIT_OPTION(state, payload){
        state.editOption = payload
    },
    SET_ACTIVATED_OPTIONS(state, payload){
        state.activatedOptions = Object.assign({}, payload)
    },
    SET_IS_EDIT_OPTION(state, payload){
        state.isEditOption = payload
    }
}
const actions = {
    async fetchUserOptions({ commit, rootState }, payload){
        const res = await ApiCaller(null, 'user').get(URLS.USER_OPTIONS(), {
            params: {service_id: payload}
        })
        if (res.data){
            commit('SET_OPTIONS', res.data.options)
            commit('store/SET_EDIT_STORE', res.data.store, {root: true})
            commit('service/SET_EDIT_SERVICE', res.data.service, {root: true})
        }
    },
    async fetchOptions({ commit, rootState }){
        const res = await ApiCaller(null, 'admin').get(URLS.OPTIONS(), {
            params: Object.assign({}, rootState.page)
        })
        if (res.data){
            commit('SET_OPTIONS', res.data.options)
            commit('page/SET_TOTAL', res.data.total, { root: true })
        }
    },
    async createOption({ state, commit, dispatch }, payload){
        const res = await ApiCaller(null, 'admin').post(URLS.OPTIONS(),{
            option: payload
        })
        if (res.data.status){
            Message.success(res.data.message)
            dispatch('fetchOptions')
        }
    },
    async updateOption({ state, commit, dispatch }, payload){
        const res = await ApiCaller(null, 'admin').put(URLS.OPTION(payload.id),{
            option: payload
        })
        if (res.data.status){
            Message.success(res.data.message)
            dispatch('fetchOptions')
        }
    },
    async removeOption({ state, commit, dispatch }, payload){
        const res = await ApiCaller(null, 'admin').delete(URLS.OPTION(payload.id))
        if (res.data.status){
            Message.success(res.data.message)
            commit('SET_VISIBLE_OPTION', false)
            dispatch('fetchOptions')
        }
    },
    async movePositionOption({ state, commit, dispatch }, payload){
        const res = await ApiCaller(null, 'admin').post(URLS.MOVE_POSITION_OPTION(payload.option_id),{
            data: payload
        })
        if (res.data.status){
            Message.success(res.data.message)
            dispatch('fetchOptions')
        }
    },
}
export default {
    namespaced: true,
    state, mutations, actions
}
