export const JWT_ADMIN_KEY = "hash_js_admin"
export const JWT_STORE_KEY = "hash_js_store"
export const JWT_ACCOUNT_KEY = "hash_js_account"
export const JWT_USER_KEY = "hash_js_user"

export function imageToBase64(file, methodsUpload) {
  var reader = new FileReader()
  var imageBase64
  reader.readAsDataURL(file)
  reader.onload = () => {
    imageBase64 = reader.result
    methodsUpload(imageBase64)
  }
}
export function image2ToBase64(file, linkType, methodsUpload) {
  var reader = new FileReader()
  var imageBase64
  reader.readAsDataURL(file)
  reader.onload = () => {
    imageBase64 = reader.result
    methodsUpload({linkType:linkType, url: imageBase64})
  }
}

export const timeIntervals = [
  {value: 15, label: "15分刻み"},
  {value: 30, label: "30分刻み"},
  {value: 60, label: "60分刻み"},
]

export const deadlineCancellations = [
  {value: 1, label: "1分前"},
  {value: 60, label: "1時間前"},
  {value: 120, label: "2時間前"},
  {value: 180, label: "3時間前"},
  {value: 360, label: "6時間前"},
  {value: 720, label: "12時間前"},
  {value: 1440, label: "24時間前(1日前)"},
  {value: 2880, label: "48時間前(2日前)"},
  {value: 4320, label: "72時間前(3日前)"},
]

export const deadlineBookingDays = [
  {value: 0, label: "0日前"},
  {value: 1, label: "1日前"},
  {value: 2, label: "2日前"},
  {value: 3, label: "3日前"},
  {value: 4, label: "4日前"},
  {value: 5, label: "5日前"},
  {value: 6, label: "6日前"},
  {value: 7, label: "7日前"},
]
export const deadlineBookingHours = [
  {value: "00:00", label: "00:00前"},
  {value: "01:00", label: "01:00前"},
  {value: "02:00", label: "02:00前"},
  {value: "03:00", label: "03:00前"},
  {value: "04:00", label: "04:00前"},
  {value: "05:00", label: "05:00前"},
  {value: "06:00", label: "06:00前"},
  {value: "07:00", label: "07:00前"},
  {value: "08:00", label: "08:00前"},
  {value: "09:00", label: "09:00前"},
  {value: "10:00", label: "10:00前"},
  {value: "11:00", label: "11:00前"},
  {value: "12:00", label: "12:00前"},
  {value: "13:00", label: "13:00前"},
  {value: "14:00", label: "14:00前"},
  {value: "15:00", label: "15:00前"},
  {value: "16:00", label: "16:00前"},
  {value: "17:00", label: "17:00前"},
  {value: "18:00", label: "18:00前"},
  {value: "19:00", label: "19:00前"},
  {value: "20:00", label: "20:00前"},
  {value: "21:00", label: "21:00前"},
  {value: "22:00", label: "22:00前"},
  {value: "23:00", label: "23:00前"},

]

export const lineNoticeBeforeDays = [
  {value: 1, label: "1日前"}
]

export const lineNoticeBeforeMinutes = [
  {value: 5, label: "5分前"},
  {value: 10, label: "10分前"},
  {value: 15, label: "15分前"},
  {value: 30, label: "30分前"},
  {value: 60, label: "60分前"},
  {value: 90, label: "90分前"},
]

export const lineNoticePeopleWaitings = [
  {value: 1, label: "1人"},
  {value: 2, label: "2人"},
  {value: 3, label: "3人"},
  {value: 4, label: "4人"},
  {value: 5, label: "5人"},
]

export const hours_in_day = [
  {value: 0, label: '0時'},
  {value: 1, label: '1時'},
  {value: 2, label: '2時'},
  {value: 3, label: '3時'},
  {value: 4, label: '4時'},
  {value: 5, label: '5時'},
  {value: 6, label: '6時'},
  {value: 7, label: '7時'},
  {value: 8, label: '8時'},
  {value: 9, label: '9時'},
  {value: 10, label: '10時'},
  {value: 11, label: '11時'},
  {value: 12, label: '12時'},
  {value: 13, label: '13時'},
  {value: 14, label: '14時'},
  {value: 15, label: '15時'},
  {value: 16, label: '16時'},
  {value: 17, label: '17時'},
  {value: 18, label: '18時'},
  {value: 19, label: '19時'},
  {value: 20, label: '20時'},
  {value: 21, label: '21時'},
  {value: 22, label: '22時'},
  {value: 23, label: '23時'},
]
export const minutes_in_hour = [
  {value: 0, label: '00分'},
  {value: 30, label: '30分'}
]

export const regexPhoneNumber = /^\d{4}(?:-\d{2}-\d{4,5}|\d{6,7})$/
export const regexKanji = /[一-龥]/
