import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";

const state = {
  departments: []
}
const mutations = {
  SET_DEPARTMENT(state, payload){
    state.departments = payload
  }
}
const actions = {
  async fetchDepartments({ commit }){
    const res = await ApiCaller().get(URLS.DEPARTMENTS())
    if (res.data){
      commit('SET_DEPARTMENT', res.data.departments)
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
